import { useEffect } from 'react';
import './App.css';
import { errorToJSON } from './helpers/jsonHelpers';
import BIT_BANK_LOGO_IMAGE from './assets/images/bit_bank_logo.png';

function App() {

  const tryToSuggestOurNetwork = () => {
    console.log("window.ethereum", 'ethereum' in window);

    if ('ethereum' in window) {
      (window.ethereum as any).request({
        method: "wallet_addEthereumChain",
        params: [{
          chainId: "0x147a0",
          rpcUrls: ["https://mainnet-rpc.zedscan.net/"],
          chainName: "ZEDX Mainnet",
          nativeCurrency: {
            name: "ZEDX Token",
            symbol: "ZEDX",
            decimals: 18
          },
          blockExplorerUrls: ["https://zedscan.net/"]
        }]
      })
      .then(() => {
        console.log("ZEDX Mainnet added to MetaMask");
      })
      .catch((error: any) => {
        console.error("Failed to add ZEDX Mainnet", error, errorToJSON(error));
        alert(`Failed to add ZEDX Mainnet: ${JSON.stringify(errorToJSON(error))}`);
      });
    } else {
      alert("Please install MetaMask to add the ZEDX network.");
    }
  }

  useEffect(() => {
    setTimeout(() => {
      tryToSuggestOurNetwork();
    }, 1500);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={BIT_BANK_LOGO_IMAGE} className="bitbank-logo" alt="BITBank logo" />

        <div className="content">
          <button 
            className={"add-network-button"}
            onClick={tryToSuggestOurNetwork}
          >
            Add network to MetaMask
          </button>
        </div>
      </header>
    </div>
  );
}

export default App;