export const errorToJSON = (error: any) => {
  if (error instanceof Error) {
    var alt: any = {};

    // @ts-ignore
    Object.getOwnPropertyNames(error).forEach((key) => alt[key] = error[key]);

    return alt;
  }

  return {error: error};
}